import './loading.css';

function Loading() {

    return (
        <div className='loadingContainer'>
          <div className='loading'/>
          <div>
            Loading ...
          </div>
        </div>
    );
}

export default Loading;
