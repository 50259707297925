import './dashBoard.css';

function DashBoard() {
    const data = {
        
    } 



    return (
        <div className="container">
        
        </div>
    );
}

export default DashBoard;
